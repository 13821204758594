<div class="dxfw-report-column-container">
  <div class="available-column">
    <form [formGroup]="selectStackReportsFormGroup">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="reportNameOptions" class="wdr-stepper-label">Report Name</label>
        </div>
        <div class="col-md-12">
          <app-select-list
            [selectFormGroup]="selectStackReportsFormGroup"
            [options]="reportNameOptions"
            [selectionConfig]="reportNameDropdownConfig"
            (selectionChange)="mapDataSource($event)"></app-select-list>
        </div>
      </div>
      <div class="row m-t-20">
        <button
          mat-button
          type="submit"
          class="add-item m-r-10"
          (click)="validateAddItem()"
          [disabled]="selectedStackReportsTableDataSource.length >= allowedReportFieldLimit">
          <mat-icon>add</mat-icon> Add Item
        </button>
        <button mat-button type="reset" (click)="resetItem()"><mat-icon>refresh</mat-icon> Reset</button>
      </div>
    </form>
  </div>
  <div class="selected-field m-t-20">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="wdr-report-column-title">Selected Fields:</label>
        <label class="wdr-stepper-label">Click and drag a field to change its display order in the report</label>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-sm-12 col-md-12 col-lg-12 p-r-0">
        <mat-table
          #selectedFieldsTable
          [dataSource]="selectedStackReportsTableDataSource?.length > 0 ? selectedStackReportsTableDataSource : emptyData"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          cdkDropListData="selectedStackReportsTableDataSource"
          [cdkDropListDisabled]="dragDisabled"
          multiTemplateDataRows>
          <ng-container matColumnDef="report_name" sticky>
            <mat-header-cell *matHeaderCellDef class="report-name-column">Report Name</mat-header-cell>
            <mat-cell *matCellDef="let element" class="report-name-column">
              <mat-icon class="dragCursor" (mousedown)="dragDisabled = false">reorder</mat-icon>
              <span>{{ element.report_name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="action-column">Action</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = dataIndex" class="action-column">
              <button mat-icon-button matTooltip="Delete" (click)="deleteReportColumn(i)">
                <mat-icon class="delete-icon">delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="empty-row">
            <mat-cell *matCellDef="let element" class="nodata">No Data Found</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="selectedFieldsTableColumn; sticky: true"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: selectedStackReportsTableDataSource?.length > 0 ? selectedFieldsTableColumn : ['empty-row']"
            cdkDrag
            [cdkDragData]="row"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
