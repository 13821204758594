import { Component } from '@angular/core';
import { ViewReportExportPdfService } from '../../services/view-report-export-pdf.service';
import { ViewReportExportExcelService } from '../../services/view-report-export-excel.service';
import { ViewReportService } from '../../services/view-report.service';
import { IMyReports } from '../../../../modules/my-reports/interface/my-reports.interface';
import * as constants from '../../../../constants/wdr.constant';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { LEVEL_CONFIG } from '../../../../constants/build-reports-stepper.constant';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss'],
})
export class ReportContentComponent {
  tableHeader = [];
  viewReportDetails: IMyReports;
  viewFilteredData;
  reportObject: any = {};
  constant = constants;

  constructor(
    public viewReportService: ViewReportService,
    private readonly exportPdfService: ViewReportExportPdfService,
    private readonly exportExcelService: ViewReportExportExcelService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService
  ) {}

  ngOnInit() {
    this.viewReportDetails = this.viewReportService.viewReportDetails;
    this.viewFilteredData = this.viewReportService.viewFilteredData;
    this.reportObject = {
      title: this.viewReportDetails?.report_title,
      date: this.viewFilteredData?.date?.value,
      tableCaption: this.joinSelectedItem(),
      template_id: this.viewReportDetails?.template_id,
    };
  }

  joinSelectedItem() {
    const propertiesToJoin = LEVEL_CONFIG?.[this.viewReportService.viewReportDetails?.level_id]?.displayMultipleObj;
    let joinedString = '';
    for (const prop of propertiesToJoin) {
      joinedString += this.viewFilteredData?.item?.selectedOptions[0][prop] + '-';
    }
    joinedString = joinedString.substring(0, joinedString.length - 1);
    return joinedString;
  }

  getExportDocumentInfo() {
    let monthInString = '',
      year = '',
      dateParts = [];
    if (this.reportObject?.date?.toString().includes('/')) {
      dateParts = this.reportObject?.date?.split('/') || [];
    }

    if (dateParts?.length === 2) {
      [monthInString, year] = dateParts;
    } else if (dateParts?.length === 3) {
      [monthInString, , year] = dateParts;
    } else {
      year = this.reportObject?.date || '';
    }
    monthInString = this.dynamicReportsUtilityService.getMonthName(monthInString) || '';
    return { ...this.reportObject, date: `${monthInString} ${year}` };
  }

  exportToExcel() {
    const reportDataForExcel = { ...this.reportObject };
    this.exportExcelService.exportToExcel(reportDataForExcel);
  }

  exportToPdf() {
    const reportDataForPDF = { ...this.reportObject };
    this.exportPdfService.exportToPdf(reportDataForPDF);
  }
}
