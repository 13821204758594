import { REPORT_ACTION } from './wdr.constant';

const CREATE_REPORT_STEPPER_TITLE = 'Create New Report';
const EDIT_REPORT_STEPPER_TITLE = 'Edit Report';
const getReportStepperTitle = (action: string) => (action === REPORT_ACTION.UPDATE ? EDIT_REPORT_STEPPER_TITLE : CREATE_REPORT_STEPPER_TITLE);
const PANEL_CLASS_SMALL = 'dxfw-wdr-dealer-select-panel-small';
const REGION_CONFIG = {
  valueObjRef: 'unique_code',
    memberId: 'region_code',
    displayObjRef: 'region_name',
    customDisplayObj: 'region_name',
    displayMultipleObj: ['region_code', 'region_name'],
    panelClass: PANEL_CLASS_SMALL,
    tableConfig: [],
}

export const DYNAMIC_REPORTS_STEPPER_HEADER = (action: string) => ({
  reportDetails: {
    title: getReportStepperTitle(action),
    description: '',
  },
  reportCriteria: {
    title: getReportStepperTitle(action),
    description: 'Select report criteria',
  },
  reportColumns: {
    title: getReportStepperTitle(action),
    description: 'Select rows',
  },
  selectStackReport: {
    title: getReportStepperTitle(action),
    description: 'Select reports',
  },
});

export const SHARE_REPORT_DIALOG_HEADER = {
  title: 'Share Report',
  description: '',
};

const COMMON_REQ_PARAMS = {
  dataSource: 'datasource_id',
  hierarchyType: 'hierarchy_id',
  hierarchyLevel: 'level_id',
  company: 'company_id',
  timePeriod: 'time_period_id',
};

/** Report Criteria */
export const REPORT_CRITERIA = {
  FORM_CONTROL_NAMES: ['dataSource', 'hierarchyType', 'hierarchyLevel', 'company', 'timePeriod'],
  FORM_CONTROL_OPTIONS: ['dataSourceOptions', 'hierarchyTypeOptions', 'hierarchyLevelOptions', 'companyOptions', 'timePeriodOptions'],
  RELATIONSHIP_KEYS_CONFIG: {
    dataSource: [],
    hierarchyType: [],
    hierarchyLevel: [{ keyName: 'hierarchy_id', controlName: 'hierarchyType' }],
    company: [],
    timePeriod: [{ keyName: 'datasource_id', controlName: 'dataSource' }],
  },
  // Used in both report criteria and report column steps
  HIERARCHICAL_REQ_PARAMS: { ...COMMON_REQ_PARAMS },
};

/**
 * Level id is used to determine the hierarchy level
 */
export const LEVEL_CONFIG = {
  10: { // Dealer - Check below const HIERARCHY_TABLE_CONFIG for tableconfig as it differs for TFS, TMNA & Dealer group
    valueObjRef: 'unique_code',
    memberId: 'dealer_code',
    displayObjRef: 'dealer_name',
    customDisplayObj: 'dealer_name',
    displayMultipleObj: ['region_code', 'district_code', 'dealer_code', 'dealer_name'],
    panelClass: 'dxfw-wdr-dealer-select-panel',
    tableConfig: [
      { header: 'Rg. code', valueRef: 'region_code', width: 50, textAlign: 'left' },
      { header: 'Dt. code', valueRef: 'district_code', width: 50, textAlign: 'left' },
      { header: 'Dl. code', valueRef: 'dealer_code', width: 60, textAlign: 'left' },
      { header: 'Dl. name', valueRef: 'dealer_name', width: 300, textAlign: 'left' },
    ],
  },
  11: { // District
    valueObjRef: 'unique_code',
    memberId: 'district_code',
    displayObjRef: 'district_name',
    customDisplayObj: 'district_name',
    displayMultipleObj: ['region_code', 'district_code', 'district_name'],
    panelClass: PANEL_CLASS_SMALL,
    tableConfig: [],
  },
  12: REGION_CONFIG, // Region
  13: REGION_CONFIG, // Company/National
  1046: REGION_CONFIG, // Company/National
  1034: { // Area
    valueObjRef: 'unique_code',
    memberId: 'area_code',
    displayObjRef: 'area_name',
    customDisplayObj: 'area_name',
    displayMultipleObj: ['area_code', 'area_name'],
    panelClass: PANEL_CLASS_SMALL,
    tableConfig: [],
  },
  1035: { // DSSO
    valueObjRef: 'unique_code',
    memberId: 'dsso_code',
    displayObjRef: 'dsso_name',
    customDisplayObj: 'dsso_name',
    displayMultipleObj: ['dsso_code', 'dsso_name'],
    panelClass: PANEL_CLASS_SMALL,
    tableConfig: [],
  },
  1112: { // Dealer Group
    valueObjRef: 'unique_code',
    memberId: 'entity_key',
    displayObjRef: 'entity_name',
    customDisplayObj: 'entity_name',
    displayMultipleObj: ['entity_name'],
    panelClass: PANEL_CLASS_SMALL,
    tableConfig: [],
  },
  1108: { // Entity
    valueObjRef: 'unique_code',
    memberId: 'entity_key',
    displayObjRef: 'entity_name',
    customDisplayObj: 'entity_name',
    displayMultipleObj: ['entity_name'],
    panelClass: PANEL_CLASS_SMALL,
    tableConfig: [],
  },
};

// Dealer level tableconfig for each Hierarchy type
export const HIERARCHY_TABLE_CONFIG = {
  3: { // TFS
    displayMultipleObj: ['region_name', 'dsso_name', 'area_code', 'dealer_code', 'dealer_name'],
    tableConfig: [
      { header: 'Rg. name', valueRef: 'region_name', width: 60, textAlign: 'left' },
      { header: 'DSSO', valueRef: 'dsso_name', width: 85, textAlign: 'left' },
      { header: 'Area', valueRef: 'area_code', width: 80, textAlign: 'left' },
      { header: 'Dl. code', valueRef: 'dealer_code', width: 75, textAlign: 'left' },
      { header: 'Dl. name', valueRef: 'dealer_name', width: 190, textAlign: 'left' },
    ],
  },
  4: { // TMNA
    displayMultipleObj: ['region_code', 'district_code', 'dealer_code', 'dealer_name'],
    tableConfig: [
      { header: 'Rg. code', valueRef: 'region_code', width: 50, textAlign: 'left' },
      { header: 'Dt. code', valueRef: 'district_code', width: 50, textAlign: 'left' },
      { header: 'Dl. code', valueRef: 'dealer_code', width: 60, textAlign: 'left' },
      { header: 'Dl. name', valueRef: 'dealer_name', width: 300, textAlign: 'left' },
    ],
  },
  7: { // Dealer Group
    displayMultipleObj: ['entity_key','dealer_code', 'dealer_name'],
    tableConfig: [
      { header: 'Entity key', valueRef: 'entity_key', width: 90, textAlign: 'left' },
      { header: 'Dl. code', valueRef: 'dealer_code', width: 85, textAlign: 'left' },
      { header: 'Dl. name', valueRef: 'dealer_name', width: 300, textAlign: 'left' },
    ],
  },
};

export const CONFIG_CONSTANTS = {
  valueObjRef: 'valueObjRef',
  displayObjRef: 'displayObjRef',
  customDisplayObj: 'customDisplayObj',
  displayMultipleObj: 'displayMultipleObj',
  panelClass: 'panelClass',
  tableConfig: 'tableConfig',
};
