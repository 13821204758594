import { Component, OnInit } from '@angular/core';
import { ViewReportService } from '../../services/view-report.service';
import * as wdrConstant from '../../../../constants/wdr.constant';
import { IGetReportTableResponse } from '../../interface/view-report.interface';

@Component({
  selector: 'app-historical-view-report',
  templateUrl: './historical-view-report.component.html',
  styleUrls: ['./historical-view-report.component.scss'],
})
export class HistoricalViewReportComponent implements OnInit {
  spanCount = 1;
  constant = wdrConstant;
  tableHeader = [];
  viewReportTableData: IGetReportTableResponse[];

  constructor(public viewReportService: ViewReportService) {}

  ngOnInit() {
    this.viewReportTableData = this.viewReportService.viewReportTableData;
    this.setReportTableConfig();
  }

  setReportTableConfig() {
    this.viewReportTableData?.forEach((tConfig) => {
      if (!this.tableHeader.length) {
        tConfig.rows.forEach((rowData) => {
          this.tableHeader.push({ columnName: rowData?.column_name, format: rowData?.format });
        });
      }
    });
  }

  rowSpan(rowIndex, colName, colIndex) {
    let i = 1;
    this.spanCount = 1;
    while (colIndex === 0 && i !== 0) {
      if (colName?.value === this.viewReportTableData?.[rowIndex + i]?.rows[0]?.value) {
        this.spanCount++;
        i++;
      } else {
        i = 0;
      }
    }
    return this.spanCount;
  }

  skipSpannedColumn(rowIndex, colName, colIndex) {
    return !(colIndex === 0 && colName?.value === this.viewReportTableData?.[rowIndex - 1]?.rows[0]?.value);
  }
}
