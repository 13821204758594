import { environment } from '../../environments/environment';
import { IGetTemplateConfig } from '../modules/view-report/interface/view-report.interface';

export const DYNAMIC_REPORTS_API_URLS = () => {
  return {
    GET_REPORT_TEMPLATES: `${environment?.API_END_POINT}/dynamicreports/get-reports-templates`,
    GET_REPORT_CONFIG: `${environment?.API_END_POINT}/dynamicreports/getreportconfig`,
    GET_REPORT_CRITERIA: `${environment?.API_END_POINT}/dynamicreports/get-report-criteria`,
    GET_REPORT_COLUMN: `${environment?.API_END_POINT}/dynamicreports/get-report-columns`,
    GENERATE_REPORT: `${environment?.API_END_POINT}/dynamicreports/save-report`,
    GET_USER_DETAILS: `${environment?.API_END_POINT}/dynamicreports/get-user-details`,
    SAVE_SHARE_REPORT: `${environment?.API_END_POINT}/dynamicreports/save-share-report`,
    GET_MY_REPORTS: `${environment?.API_END_POINT}/dynamicreports/get-reports-by-user-id`,
    DELETE_REPORT: `${environment?.API_END_POINT}/dynamicreports/delete-report`,
    GET_SHARED_REPORT: `${environment?.API_END_POINT}/dynamicreports/get-shared-reports-by-user-id`,
    GET_REPORT_FILTER: `${environment?.API_END_POINT}/dynamicreports/get-report-filter`,
    GET_VIEW_REPORT: `${environment?.API_END_POINT}/dynamicreports/{template-name}-{datasource_name}-report`,
  };
};

export const REPORT_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  COPY: 'copy',
  DELETE: 'delete',
  SHARE: 'share',
};

export const CONFIRM_MESSAGE = {
  COPY_REPORT: (reportTitle: string) => `Are you sure your want to copy '<strong>${reportTitle}</strong>' report?`,
  DELETE_REPORT: (reportTitle: string) => `Are you sure your want to delete '<strong>${reportTitle}</strong>' report?`,
};

export const DEFAULT_BUTTON_LABELS = {
  CONFIRM_LABEL: 'Yes',
  CANCEL_LABEL: 'No',
};

export const MENU_TAB_CONFIG = [
  {
    label: 'Build Reports',
    path: '/workout-dynamic-reports/home',
  },
  {
    label: 'My Reports',
    path: '/workout-dynamic-reports/my-reports',
  },
  {
    label: 'Shared Reports',
    path: '/workout-dynamic-reports/shared-reports',
  },
];

export const REPORT_CATEGORY = {
  SHARED: 'shared-reports',
  MY_REPORT: 'my-reports',
};

export const WDR_CONSTANT = Object.freeze({
  forgeRockSessionTime: 45 * 60 * 1000,
  fieldRequiredMessage: 'This field is required',
  snackBarDuration: 5000,
  allowedReportFieldLimit: 25,
  fieldAlreadyExistMessage: 'Field already exist in the selected field list.',
  reportAlreadyExistMessage: 'Report already exist in the selected reports list.',
  fieldAddedSuccessMessage: 'Field added successfully.',
  reportAddedSuccessMessage: 'Report added successfully.',
  noReportsMessage: 'No reports found',
  matOptionLimit: 10,
  defaultMatOptionColumnWidth: 100,
  noSearchResultMessage: 'No results found',
  noOptionsMessage: 'Empty option data',
  errorMessages: {
    genericException:
      'An exception occurred. Kindly report this incident to Application Support team (with a screenshot of this message) and proceed with your task.',
  },
  blockScopeMessage: 'Report is currently under construction. Please check back later.',
  noItemsFound: "No items found"
});

export const MAT_PAGINATOR_CONFIG = {
  pageSize: 10,
  currentPage: 0,
  pageSizeOptions: [5, 10, 15, 25, 100],
  showFirstLastButtons: true,
  selectConfig: { panelClass: 'dxfw-wdr-select-panel' },
};

export const TEMPLATE_ID = {
  HIERARCHICAL: 1,
  COMPARISON: 2,
  HISTORICAL: 3,
  STACK: 4,
  EXTERNAL_COMPARISON: 5,
};

export const LEVEL_ID = {
  DEALER: 10,
  DISTRICT: 11,
  REGION: 12,
};

export const DATASOURCE_CONFIG: IGetTemplateConfig = {
  1: {
    name: 'hierarchical',
    datasource: {
      1: 'financial',
      2: 'sales'
    }
  },
  2: {
    name: 'comparison',
    datasource: {
      1: 'financial',
      2: 'sales'
    }
  },
  3: {
    name: 'historical',
    datasource: {
      1: 'financial',
      2: 'sales'
    }
  }
};

// View report table row color
export const VIEW_REPORT_COLOR_CODE = {
  10: '#FFFFFF',
  11: '#CFEBE6',
  12: '#F0D9D2',
  13: '#EBE7B2',
  1046: '#EBE7B2',
};