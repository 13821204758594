<div class="report-table">
  <table aria-describedby="dxfw-report-content-table" id="dxfw-wdr-view-report-table">
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader; index as headerIndex" class="dxfw-wdr-view-report-header-cell">
          {{ header?.columnName }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rowObj of viewReportTableData">
        <td
          *ngFor="let colName of rowObj?.rows; index as dataIndex"
          class="dxfw-wdr-view-report-body-cell"
          [ngClass]="!colName?.value || colName?.value === '-' ? 'text-center' : colName?.format ? 'text-right' : 'text-left'">
          {{
            colName?.value === '-'
              ? colName?.value
              : colName?.value && colName?.format === 'currency'
              ? (colName?.value | currency)
              : colName?.value && colName?.format === 'percent'
              ? (colName?.value / 100 | percent)
              : colName?.value ?? '-'
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
