<div class="report-content-container">
  <div class="d-flex">
    <div class="report-title">
      {{ reportObject?.tableCaption }}
    </div>
    <div class="export-icon">
      <mat-icon class="icon" (click)="exportToPdf()" (keydown)="exportToPdf()">picture_as_pdf</mat-icon>
      <svg (click)="exportToExcel()" (keydown)="exportToExcel()" class="icon" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M14 2H6a2 2 0 0 0-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8zm4 18H6V4h7v5h5zm-5.1-5.5l2.9 4.5H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 10H10l2 3.4l2-3.4h1.8z"></path>
      </svg>
    </div>
  </div>
  <app-hierarchical-view-report *ngIf="viewReportDetails?.template_id === constant?.TEMPLATE_ID?.HIERARCHICAL"></app-hierarchical-view-report>
  <app-historical-view-report *ngIf="viewReportDetails?.template_id === constant?.TEMPLATE_ID?.HISTORICAL"></app-historical-view-report>
  <app-comparison-view-report *ngIf="viewReportDetails?.template_id === constant?.TEMPLATE_ID?.COMPARISON"></app-comparison-view-report>
</div>
