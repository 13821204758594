import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs';
import * as FileSaver from 'file-saver';
import * as constants from '../../../constants/wdr.constant';
import { SpinnerService } from '../../../shared/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ViewReportExportExcelService {
  constructor(private readonly spinnerService: SpinnerService) {}

  updateTitleRowDateColumn(worksheet: ExcelJS.Worksheet, currentRow, cellValue: string): void {
    const titleRowDateColumnIndex = 4;
    const dateColumnCell = worksheet.getCell(currentRow, titleRowDateColumnIndex);
    dateColumnCell.value = cellValue;
    dateColumnCell.font = { name: 'Arial', size: 9, bold: true };
    dateColumnCell.alignment = { vertical: 'middle', horizontal: 'left' };
  }

  saveExcel(workbook: ExcelJS.Workbook, fileName: string): void {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  }

  sanitizeDate(date: string): string {
    return date.replace(/[\\/*?:[\]]/g, '_');
  }

  exportToExcel(reportData): void {
    this.spinnerService.displaySpinner();
    const titleText = reportData.title.toUpperCase();
    const fileName = `${titleText}_${this.sanitizeDate(reportData.date.toString())}_${Date.now()}`;
    const sheetName = `${titleText}_${this.sanitizeDate(reportData.date.toString())}`;
    const tableCaptionText = reportData.tableCaption;
    const tables = document.querySelectorAll('table');
    const workbook = new ExcelJS.Workbook();
    const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(`${sheetName}`);

    const columnWidths: number[] = [];
    let currentRow = 1;

    const titleRow = worksheet.addRow([titleText]);
    titleRow.font = { name: 'Arial', size: 9, bold: true };
    titleRow.alignment = { vertical: 'middle', horizontal: 'left' };
    worksheet.mergeCells('A1:C1');
    this.updateTitleRowDateColumn(worksheet, currentRow, `DATE: (${reportData.date.toString().toUpperCase()})`);

    worksheet.getRow(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Matching the #F0D9D2 color

    const tableCaptionRow = worksheet.addRow([tableCaptionText]);
    tableCaptionRow.alignment = { vertical: 'middle', horizontal: 'center' };
    if (reportData.template_id === constants.TEMPLATE_ID.HISTORICAL) {
      worksheet.mergeCells('A3:N3');
    } else {
      worksheet.mergeCells('A3:D3');
    }
    currentRow = 4;

    const fontStyle: Partial<ExcelJS.Font> = { name: 'Arial', bold: false, size: 9, color: { argb: '262626' } };
    const headerBorderStyle: Partial<ExcelJS.Border> = { style: 'medium', color: { argb: '9A9064' } };
    const headerFillStyle: ExcelJS.Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C7C1A9' } };
    const cellBorderStyle: Partial<ExcelJS.Border> = { style: 'medium', color: { argb: 'E5EBEB' } };
    const bodyFillStyle: ExcelJS.Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F0F0F0' } };

    tables.forEach((table) => {
      const processRows = (rows, isHeader) => {
        rows.forEach((row, rowIndex) => {
          const cells = row.querySelectorAll(isHeader ? 'th' : 'td');
          const excelRow = worksheet.getRow(currentRow);
          let cellIndex = 1;

          cells.forEach((cell) => {
            const colspan = cell.getAttribute('colspan');
            const excelCell: ExcelJS.Cell = excelRow.getCell(cellIndex);
            excelCell.value = cell.textContent || '';
            excelCell.border = { top: cellBorderStyle, left: cellBorderStyle, bottom: cellBorderStyle, right: cellBorderStyle };
            excelCell.font = fontStyle;

            const textLength = excelCell.value ? excelCell.value.toString().length : 10;
            columnWidths[cellIndex - 1] = Math.max(columnWidths[cellIndex - 1] || 0, textLength);

            if (colspan && Number(colspan) > 1) {
              const mergeEnd = cellIndex + Number(colspan) - 1;
              worksheet.mergeCells(currentRow, cellIndex, currentRow, mergeEnd);
              cellIndex = mergeEnd + 1;
            } else {
              cellIndex++;
            }

            if (isHeader) {
              // Header section styles
              if (cell.classList.contains('dxfw-wdr-view-report-header-cell')) {
                excelCell.font = { ...fontStyle, bold: true };
                excelCell.fill = headerFillStyle;
                excelCell.border = { top: headerBorderStyle, left: headerBorderStyle, bottom: headerBorderStyle, right: headerBorderStyle };
                excelCell.alignment = { ...excelCell.alignment, horizontal: 'center' };
              }
            } else if (!isHeader) {
              // Body section styles
              if ((rowIndex + 1) % 2 === 0) {
                excelCell.fill = bodyFillStyle;
              }

              if (reportData.template_id === constants.TEMPLATE_ID.HIERARCHICAL) {
                excelCell.alignment = { vertical: 'middle', horizontal: cellIndex < 4 ? 'left' : 'right' };
                const backgroundColor = (row as HTMLElement).style.backgroundColor;
                if (backgroundColor) {
                  excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.rgbToHex(backgroundColor) } } as ExcelJS.Fill;
                }
              } else if (reportData.template_id === constants.TEMPLATE_ID.HISTORICAL) {
                excelCell.alignment = {
                  vertical: 'middle',
                  horizontal: cellIndex === 2 ? 'left' : cellIndex === 3 ? 'center' : 'right',
                };
              } else if (reportData.template_id === constants.TEMPLATE_ID.COMPARISON) {
                excelCell.alignment = {
                  vertical: 'middle',
                  horizontal: cellIndex === 2 ? 'left' : 'right',
                };
              }
            }
          });
          currentRow++;
        });
      };

      processRows(table.querySelectorAll('thead tr'), true);
      processRows(table.querySelectorAll('tbody tr'), false);
      currentRow++;
    });

    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width + 2;
    });

    this.saveExcel(workbook, fileName);
    this.spinnerService.hideSpinner();
  }

  rgbToHex(rgb: string): string {
    const rgbValues = rgb.match(/\d+/g);
    if (!rgbValues || rgbValues.length !== 3) {
      throw new Error('Invalid RGB color format');
    }
    return rgbValues
      .map((value) => {
        const hex = parseInt(value).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('');
  }
}
