import { IDynamicReportDropdownConfig } from '../shared/interface/common.interface';

export const DROPDOWN_CONFIG: IDynamicReportDropdownConfig = {
  REPORT_COLUMN_CONTROL: {
    FIELD_NAME: {
      controlName: 'fieldName',
      valueObjRef: 'id',
      displayObjRef: 'field_name',
      enableSearch: true,
      placeHolder: 'Select Field Name',
    },
    FIELD_CATEGORY: {
      controlName: 'fieldCategory',
      valueObjRef: 'id',
      displayObjRef: 'category_name',
      enableSearch: true,
      placeHolder: 'Select Field Category',
    },
    FIELD_TIME_PERIOD: {
      controlName: 'fieldTimePeriod',
      valueObjRef: 'id',
      displayObjRef: 'time_period_name',
      enableSearch: true,
      placeHolder: 'Select Time Period',
    },
    COMPARISON: {
      controlName: 'comparision',
      valueObjRef: 'id',
      displayObjRef: 'comparision_name',
      enableSearch: true,
      placeHolder: 'Select Comparison',
    },
    COMPARATOR_QUALIFIER: {
      controlName: 'comparatorQualifier',
      valueObjRef: 'id',
      displayObjRef: 'comparator_qualifier_name',
      enableSearch: true,
      placeHolder: 'Select Comparator Qualifier',
    },
  },
  SELECT_REPORT_CONTROL: {
    REPORT_NAME: {
      controlName: 'reportName',
      valueObjRef: 'id',
      displayObjRef: 'report_title',
      enableSearch: true,
      placeHolder: 'Please Select A Report',
    },
  },
  REPORT_FILTER_CONTROL: {
    DATE_CONTROL: {
      controlName: 'date',
      enableSearch: true,
      placeHolder: 'Select Date',
      matOptionLimit: 100,
    },
    ITEM_CONTROL: {
      controlName: 'item',
      enableSearch: true,
      placeHolder: 'Select Item',
      valueObjRef: 'unique_code',
      memberId: 'dealer_code',
      displayObjRef: 'dealer_name',
      displayMultipleObj: [],
      customDisplayObj: 'dealer_name',
      panelClass: 'dxfw-wdr-dealer-select-panel',
      tableConfig: [
        { header: 'Rg. code', valueRef: 'region_code', width: 20, textAlign: 'left' },
        { header: 'Dt. code', valueRef: 'district_code', width: 30, textAlign: 'left' },
        { header: 'Dl. code', valueRef: 'dealer_code', width: 30, textAlign: 'left' },
        { header: 'Dl. name', valueRef: 'dealer_name', width: 50, textAlign: 'left' },
      ],
      matOptionLimit: 100,
    },
    COMPARATOR_CONTROL: {
      controlName: 'comparator',
      enableSearch: true,
      placeHolder: 'Select Comparator',
      valueObjRef: 'dealer_code',
      displayObjRef: 'dealer_name',
      displayMultipleObj: [],
      customDisplayObj: 'dealer_name',
      panelClass: 'dxfw-wdr-dealer-select-panel',
    },
  },
  SHARED_REPORT_CONTROL: {
    SEARCH_USERS_CONTROL: {
      controlName: 'searchUsers',
      enableSearch: true,
      enableMultiSelection: true,
      placeHolder: 'Search Users',
      valueObjRef: 'user_id',
      displayObjRef: 'user',
      customDisplayObj: 'user',
      matOptionLimit: 30,
    },
  },
};
