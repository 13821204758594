import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DYNAMIC_REPORTS_STEPPER_HEADER, REPORT_CRITERIA } from '../../../constants/build-reports-stepper.constant';
import { IGenerateReportRequest, IReportCriteriaResponse, IReportSourceFields } from '../../interface/build-reports-stepper.interface';
import { DynamicReportsDialogService } from '../../services/dynamic-reports-dialog.service';
import { DynamicReportsUtilityService } from '../../services/dynamic-reports-utility.service';
import { ShareReportDialogComponent } from '../share-report-dialog/share-report-dialog.component';
import { REPORT_ACTION, TEMPLATE_ID } from '../../../constants/wdr.constant';
import { MyReportsService } from '../../../modules/my-reports/services/my-reports.service';

@Component({
  selector: 'app-build-reports-stepper-dialog',
  templateUrl: './build-reports-stepper-dialog.component.html',
  styleUrls: ['./build-reports-stepper-dialog.component.scss'],
})
export class BuildReportsStepperDialogComponent {
  reportDetailsFormGroup = this._formBuilder.group({
    reportType: [{ value: '', disabled: true }, Validators.required],
    reportTitle: ['', Validators.required],
    reportDescription: ['', Validators.required],
  });
  reportCriteriaFormGroup: FormGroup; //dynamic form group
  reportColumnFormGroup: FormGroup; //dynamic form group
  selectStackReportsFormGroup: FormGroup; //dynamic form group

  isStackedReport = false;
  isLinear = true;
  stepperHeader = DYNAMIC_REPORTS_STEPPER_HEADER(this.modalData.reportAction);

  reportCriteriaResponseData: IReportCriteriaResponse;
  reportCriteriaFormData: any;
  compareReportCriteriaFormData: any;
  selectedFieldsTableDataSource: any[] = [];
  selectedStackReportsTableDataSource: any[] = [];
  generateReportRequest: IGenerateReportRequest;

  constructor(
    public dialogRef: MatDialogRef<BuildReportsStepperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private readonly _formBuilder: FormBuilder,
    private readonly dynamicReportsDialogService: DynamicReportsDialogService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    private readonly myReportsService: MyReportsService
  ) {}

  ngOnInit(): void {
    this.setStackedReportFlag();
    this.reportCriteriaFormGroup = this._formBuilder.group(this.getReportCriteriaFormGroupControls(this.modalData.reportType.id));
    this.reportColumnFormGroup = this._formBuilder.group(this.getReportColumnFormGroupControls(this.modalData.reportType.id));
    this.selectStackReportsFormGroup = this._formBuilder.group({ reportName: ['', Validators.required] });
    this.setFormValuesByAction();
  }

  setStackedReportFlag(): void {
    this.isStackedReport = this.modalData.reportType.id === TEMPLATE_ID.STACK;
  }

  getReportCriteriaFormGroupControls(templateId: number) {
    // Note: Control Names and IReportCriteriaResponse interface key names must be the same
    const commonControls = {
      dataSource: ['', Validators.required],
      hierarchyType: [{ value: '', disabled: true }, Validators.required],
      hierarchyLevel: [{ value: '', disabled: true }, Validators.required],
      company: [{ value: '', disabled: true }, Validators.required],
      timePeriod: [{ value: '', disabled: true }, Validators.required],
    };
    if (templateId === TEMPLATE_ID.HIERARCHICAL) {
      return {
        ...commonControls,
        showAllDealers: [false],
        showTerminatedDealers: [false],
      };
    } else if (templateId === TEMPLATE_ID.COMPARISON) {
      return commonControls;
    } else if (templateId === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      return {
        ...commonControls,
        comparatorCriteriaHierarchyLevel: [{ value: '', disabled: true }, Validators.required],
      };
    } else if (templateId === TEMPLATE_ID.HISTORICAL) {
      const newObject = { ...commonControls };
      delete newObject.timePeriod;
      return {
        ...newObject,
        showTotalColumn: [false],
      };
    } else {
      return {};
    }
  }

  getReportColumnFormGroupControls(templateId: number) {
    const commonControls = {
      fieldName: ['', Validators.required],
      fieldCategory: [{ value: '', disabled: true }, Validators.required],
      fieldTimePeriod: [{ value: '', disabled: true }, Validators.required],
    };
    if (templateId === TEMPLATE_ID.HIERARCHICAL || templateId === TEMPLATE_ID.HISTORICAL) {
      return commonControls;
    } else if (templateId === TEMPLATE_ID.COMPARISON) {
      return {
        ...commonControls,
        comparision: [{ value: '', disabled: true }, Validators.required],
      };
    } else if (templateId === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      return {
        ...commonControls,
        displayName: ['', Validators.required],
        comparatorQualifier: [{ value: '', disabled: true }, Validators.required],
      };
    } else {
      return {};
    }
  }

  setFormValuesByAction(): void {
    if (this.modalData.reportAction === REPORT_ACTION.UPDATE) {
      this.setReportDetailsFormValues({
        reportType: this.modalData.reportDetails.template_name,
        reportTitle: this.modalData.reportDetails.report_title,
        reportDescription: this.modalData.reportDetails.report_description,
      });
      this.setCompareReportCriteriaFormData();
      this.selectedFieldsTableDataSource = this.modalData.reportDetails.sourceFields;
    } else {
      this.setReportDetailsFormValues({ reportType: this.modalData.reportType.template_name }); // Action - Add New Report
    }
  }

  setCompareReportCriteriaFormData() {
    let mapReportCriteriaFields = { ...REPORT_CRITERIA.HIERARCHICAL_REQ_PARAMS };
    if (this.modalData.reportDetails.template_id === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      mapReportCriteriaFields = { ...mapReportCriteriaFields, ...{ comparatorCriteriaHierarchyLevel: 'comparison_type_rcid' } };
    }
    this.compareReportCriteriaFormData = { template_id: this.modalData.reportDetails.template_id };
    for (const mapValue of Object.values(mapReportCriteriaFields)) {
      this.compareReportCriteriaFormData[mapValue] = +this.modalData.reportDetails[mapValue];
    }
  }

  setReportDetailsFormValues(valuesObj): void {
    this.reportDetailsFormGroup.patchValue(valuesObj);
  }

  getReportColumnReqParamData() {
    const templateId = this.modalData.reportType.id;
    const reqParams = { ...REPORT_CRITERIA.HIERARCHICAL_REQ_PARAMS };
    if (templateId === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      reqParams['comparatorCriteriaHierarchyLevel'] = 'comparison_type_rcid';
    }
    return reqParams;
  }

  onClickReportCriteriaNextBtn(): void {
    if (this.reportCriteriaFormGroup.valid) {
      const mapReportCriteriaFields = this.getReportColumnReqParamData();
      this.reportCriteriaFormData = { template_id: this.modalData.reportType.id };
      for (const [controlName, mapValue] of Object.entries(mapReportCriteriaFields)) {
        this.reportCriteriaFormData[mapValue] = +this.reportCriteriaFormGroup.get(controlName)?.value;
      }
      if (this.modalData.reportType.id === TEMPLATE_ID.HISTORICAL) {
        this.reportCriteriaFormData['time_period_id'] = 0; // For Historical Report to be removed after backend mandatory check has been removed
      }
      //resetting selectedFieldsTableDataSource when report criteria data changed
      if (!this.dynamicReportsUtilityService.compareObject(this.reportCriteriaFormData, this.compareReportCriteriaFormData)) {
        this.compareReportCriteriaFormData = this.dynamicReportsUtilityService.deepCopy(this.reportCriteriaFormData);
        this.selectedFieldsTableDataSource = [];
      }
    }
  }

  isFormGroupsValid(): boolean {
    if (this.modalData.reportType.id !== TEMPLATE_ID.STACK) {
      return this.reportDetailsFormGroup.valid && this.reportCriteriaFormGroup.valid && this.selectedFieldsTableDataSource.length > 0;
    }
    return this.reportDetailsFormGroup.valid && this.selectedStackReportsTableDataSource.length > 0;
  }

  onClickGenerateReport(): void {
    if (this.isFormGroupsValid()) {
      this.generateReportRequest = this.prepareGenerateReportRequest();
      this.dynamicReportsDialogService.generateReport(this.generateReportRequest).subscribe({
        next: (responseData) => {
          this.dynamicReportsUtilityService
            .showSuccessMessage(responseData.message)
            .afterDismissed()
            .subscribe(() => {
              this.closeDialog(true);
              // this.showShareReport(responseData.report_id); // Disabled As of now to show Share Report Dialog to avoid showing multiple time both create and viewing report
            });
        },
        error: (responseData) => {
          this.dynamicReportsUtilityService.showErrorMessage(responseData.message);
        },
      });
    }
  }

  getInitialRequestData(reportDetails, reportCriteria): IGenerateReportRequest {
    if (this.modalData.reportType.id !== TEMPLATE_ID.STACK) {
      return {
        templateId: this.modalData.reportType.id,
        reportTitle: reportDetails['reportTitle'],
        reportDescription: reportDetails['reportDescription'],
        dataSourceId: +reportCriteria['dataSource'],
        hierarchyId: +reportCriteria['hierarchyType'],
        hierarchyLevelId: +reportCriteria['hierarchyLevel'],
        companyId: +reportCriteria['company'],
        timePeriodId: +reportCriteria['timePeriod'] || 0,
        showTotalColumn: reportCriteria['showTotalColumn'] || null,
        isEnabled: true,
        action: this.modalData.reportAction,
        sourceFields: this.prepareSourceFields(this.modalData.reportType.id),
      };
    }
    return {
      templateId: this.modalData.reportType.id,
      reportTitle: reportDetails['reportTitle'],
      reportDescription: reportDetails['reportDescription'],
      isEnabled: true,
      action: this.modalData.reportAction,
      subReportId: this.getSelectedStackReportId(),
    } as IGenerateReportRequest; // STACK Report
  }

  prepareGenerateReportRequest(): IGenerateReportRequest {
    const reportDetails = this.reportDetailsFormGroup.getRawValue();
    const reportCriteria = this.reportCriteriaFormGroup.getRawValue();
    let generateReportRequest: IGenerateReportRequest = this.getInitialRequestData(reportDetails, reportCriteria);
    if (this.modalData.reportType.id === TEMPLATE_ID.HIERARCHICAL) {
      generateReportRequest.hierarchialShowAllDealer = reportCriteria['showAllDealers'];
      generateReportRequest.hierarchialShowTermDealer = reportCriteria['showTerminatedDealers'];
    } else if (this.modalData.reportType.id === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      generateReportRequest.comparisonTypeRcId = +reportCriteria['comparatorCriteriaHierarchyLevel'];
    }
    if (this.modalData.reportAction === REPORT_ACTION.UPDATE) {
      generateReportRequest.id = this.modalData.reportDetails.id;
    }
    return generateReportRequest;
  }

  getSelectedStackReportId(): number[] {
    return this.selectedStackReportsTableDataSource.map((report) => report['report_id']) || [];
  }

  prepareSourceFields(templateId: number): IReportSourceFields[] {
    return this.selectedFieldsTableDataSource.map((sourceField, indx) => {
      ++indx;
      const sourceFieldObj = {
        sourceFieldId: sourceField['source_field_id'],
        categoryId: sourceField['category_id'],
        timePeriod: sourceField['time_period_id'],
        isEnabledSrcField: true,
        isEnabledSrcFieldCat: true,
        displaySeq: indx,
      };
      if (templateId === TEMPLATE_ID.COMPARISON) {
        // Comparision Report
        sourceFieldObj['comparisonTypeId'] = sourceField['comparison_type_id'];
      } else if (templateId === TEMPLATE_ID.EXTERNAL_COMPARISON) {
        sourceFieldObj['displayName'] = sourceField['display_name'] || null;
        sourceFieldObj['comparisonTypeId'] = sourceField['comparison_type_id'];
      }
      return sourceFieldObj;
    });
  }

  showShareReport(reportId = ''): void {
    this.dynamicReportsUtilityService.openDialog(ShareReportDialogComponent, {
      data: { reportAction: REPORT_ACTION.CREATE, reportId },
    });
  }

  closeDialog(isReportUpdated = false): void {
    this.dialogRef.close();
    isReportUpdated && this.refreshReportsTab(); // After Edit report needs to refresh the reports tab to update latest changes
  }

  refreshReportsTab(): void {
    this.myReportsService.isReportUpdatedObs.next(true);
  }
}
