import { Component } from '@angular/core';
import * as wdrConstant from '../../../../constants/wdr.constant';
import { ViewReportService } from '../../services/view-report.service';
import { IGetReportTableResponse } from '../../interface/view-report.interface';

@Component({
  selector: 'app-comparison-view-report',
  templateUrl: './comparison-view-report.component.html',
  styleUrls: ['./comparison-view-report.component.scss'],
})
export class ComparisonViewReportComponent {
  viewReportTableData: IGetReportTableResponse[];
  constant = wdrConstant;
  tableHeader = [];

  constructor(public viewReportService: ViewReportService) {}

  ngOnInit() {
    this.viewReportTableData = this.viewReportService.viewReportTableData;
    this.setReportTableConfig();
  }

  setReportTableConfig() {
    this.viewReportTableData?.forEach((tConfig) => {
      if (!this.tableHeader.length) {
        tConfig.rows.forEach((rowData) => {
          this.tableHeader.push({ columnName: rowData?.column_name, format: rowData?.format });
        });
      }
    });
  }
}
