import { Component } from '@angular/core';
import { ViewReportExportPdfService } from '../../services/view-report-export-pdf.service';
import { ViewReportExportExcelService } from '../../services/view-report-export-excel.service';
import { ViewReportService } from '../../services/view-report.service';
import { IMyReports } from '../../../../modules/my-reports/interface/my-reports.interface';
import * as constants from '../../../../constants/wdr.constant';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { LEVEL_CONFIG } from '../../../../constants/build-reports-stepper.constant';
import { IGetReportTableResponse, IViewReportExportDocData, IViewReportExportDocHeaderDetails } from '../../interface/view-report.interface';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss'],
})
export class ReportContentComponent {
  tableHeader = [];
  viewReportDetails: IMyReports;
  viewFilteredData;
  reportObject: IViewReportExportDocHeaderDetails[] = [];
  constant = constants;
  viewReportTables: IGetReportTableResponse[][];
  spanCount = [];

  constructor(
    public viewReportService: ViewReportService,
    private readonly exportPdfService: ViewReportExportPdfService,
    private readonly exportExcelService: ViewReportExportExcelService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService
  ) {}

  ngOnInit() {
    this.viewReportTables = this.viewReportService.viewReportTableData;
    this.viewReportDetails = this.viewReportService.viewReportDetails;
    this.viewFilteredData = this.viewReportService.viewFilteredData;
    this.setTablesSpanCount();
    this.setReportTableConfig();
    this.setReportObjectToExportDoc();
  }

  isStackedReport() {
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.STACK;
  }

  setTablesSpanCount(): void {
    this.spanCount = Array(this.viewReportTables.length).fill(1);
    this.viewReportTables?.forEach(() => this.tableHeader.push([]));
  }

  setReportObjectToExportDoc() {
    if (this.isStackedReport()) {
      this.viewReportTables?.forEach((_viewReportTable, tableIndex) => {
        this.reportObject.push({
          title: this.viewReportDetails.stackedReportDetails[tableIndex]?.report_name,
          date: this.viewFilteredData[tableIndex]?.date?.value,
          tableCaption: this.joinSelectedItem(tableIndex),
          template_id: this.viewReportDetails.stackedReportDetails[tableIndex]?.template_id,
        });
      });
    } else {
      this.reportObject.push({
        title: this.viewReportDetails?.report_title,
        date: this.viewFilteredData[0]?.date?.value,
        tableCaption: this.joinSelectedItem(0),
        template_id: this.viewReportDetails?.template_id,
      });
    }
  }

  setReportTableConfig() {
    this.viewReportTables?.forEach((viewReportTable, tableIndex) => {
      viewReportTable?.forEach((tConfig) => {
        if (!this.tableHeader[tableIndex].length) {
          tConfig?.rows?.forEach((rowData) => {
            this.tableHeader[tableIndex].push({ columnName: rowData?.column_name, format: rowData?.format });
          });
        }
      });
    });
  }

  rowSpan(tableIndex, rowIndex, colName, colIndex) {
    let i = 1;
    this.spanCount[tableIndex] = 1;
    while (colIndex === 0 && i !== 0) {
      if (colName?.value === this.viewReportTables[tableIndex]?.[rowIndex + i]?.rows[0]?.value) {
        this.spanCount[tableIndex]++;
        i++;
      } else {
        i = 0;
      }
    }
    return this.spanCount[tableIndex];
  }

  skipSpannedColumn(tableIndex, rowIndex, colName, colIndex) {
    return !(colIndex === 0 && colName?.value === this.viewReportTables[tableIndex]?.[rowIndex - 1]?.rows[0]?.value);
  }

  isHierarchicalReport(tableIndex: number) {
    if (this.isStackedReport()) {
      return this.viewReportDetails.stackedReportDetails[tableIndex]?.template_id === constants.TEMPLATE_ID.HIERARCHICAL;
    }
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.HIERARCHICAL;
  }

  isComparisonReport(tableIndex: number) {
    if (this.isStackedReport()) {
      return this.viewReportDetails.stackedReportDetails[tableIndex]?.template_id === constants.TEMPLATE_ID.COMPARISON;
    }
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.COMPARISON;
  }

  isHistoricalReport(tableIndex: number) {
    if (this.isStackedReport()) {
      return this.viewReportDetails.stackedReportDetails[tableIndex]?.template_id === constants.TEMPLATE_ID.HISTORICAL;
    }
    return this.viewReportDetails?.template_id === constants.TEMPLATE_ID.HISTORICAL;
  }

  joinSelectedItem(tableIndex: number) {
    const levelId = this.isStackedReport() ? this.viewReportDetails.stackedReportDetails[tableIndex]?.level_id : this.viewReportDetails?.level_id;
    const propertiesToJoin = LEVEL_CONFIG?.[levelId]?.displayMultipleObj;
    let joinedString = '';
    propertiesToJoin?.forEach((element: any) => {
      joinedString += this.viewFilteredData[tableIndex]?.item?.selectedOptions[0][element] + '-';
    });
    joinedString = joinedString.substring(0, joinedString.length - 1);
    return joinedString;
  }

  getExportDocumentInfo(tableIndex) {
    let monthInString = '',
      year = '',
      dateParts = [];
    if (this.reportObject[tableIndex]?.date?.toString().includes('/')) {
      dateParts = this.reportObject[tableIndex]?.date?.split('/') || [];
    }

    if (dateParts?.length === 2) {
      [monthInString, year] = dateParts;
    } else if (dateParts?.length === 3) {
      [monthInString, , year] = dateParts;
    } else {
      year = this.reportObject[tableIndex]?.date || '';
    }
    monthInString = this.dynamicReportsUtilityService.getMonthName(monthInString) || '';
    return { ...this.reportObject[tableIndex], date: `${monthInString} ${year}` };
  }

  getExportDocProps(): IViewReportExportDocData {
    return {
      reportData: this.dynamicReportsUtilityService.deepCopy(this.reportObject),
      isStackedReport: this.isStackedReport(),
      viewReportDetails: this.dynamicReportsUtilityService.deepCopy(this.viewReportDetails),
      viewReportTables: this.dynamicReportsUtilityService.deepCopy(this.viewReportTables),
    };
  }

  exportToExcel() {
    this.exportExcelService.exportToExcel(this.getExportDocProps());
  }

  exportToPdf() {
    this.exportPdfService.exportToPdf(this.getExportDocProps());
  }
}
