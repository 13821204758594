import { Injectable } from '@angular/core';
import { IMyReports } from '../../my-reports/interface/my-reports.interface';
import { DynamicReportsHttpService } from '../../../shared/services/dynamic-reports-http.service';
import { Observable } from 'rxjs';
import { DATASOURCE_CONFIG, DYNAMIC_REPORTS_API_URLS } from '../../../constants/wdr.constant';
import { COMPARATOR_CONFIG_FIELD, LEVEL_CONFIG } from '../../../constants/build-reports-stepper.constant';
import { DROPDOWN_CONFIG } from '../../../constants/dropdown-config.constant';
import { IGetReportFilterRequest, IGetReportFilterResponse } from '../interface/view-report.interface';

@Injectable({
  providedIn: 'root',
})
export class ViewReportService {
  private reportDetails: IMyReports;
  private isReportShared: boolean;
  private reportTableData;
  private filterData;

  constructor(private readonly dynamicReportsHttpService: DynamicReportsHttpService) {}

  set viewReportDetails(reportDetails: IMyReports) {
    this.reportDetails = reportDetails;
  }

  get viewReportDetails(): IMyReports {
    return this.reportDetails;
  }

  set isSharedReport(isReportShared: boolean) {
    this.isReportShared = isReportShared;
  }

  get isSharedReport(): boolean {
    return this.isReportShared;
  }

  setComparatorCriteria() {
    for (const field in COMPARATOR_CONFIG_FIELD) {
      DROPDOWN_CONFIG.REPORT_FILTER_CONTROL.COMPARATOR_CONTROL[COMPARATOR_CONFIG_FIELD[field]] =
        LEVEL_CONFIG[this.viewReportDetails.level_id][COMPARATOR_CONFIG_FIELD[field]];
    }
  }

  set viewReportTableData(tableResponse) {
    this.reportTableData = tableResponse;
  }

  get viewReportTableData() {
    return this.reportTableData;
  }

  set viewFilteredData(filterData) {
    this.filterData = filterData;
  }

  get viewFilteredData() {
    return this.filterData;
  }

  getReportFilter(reqParams: IGetReportFilterRequest): Observable<IGetReportFilterResponse> {
    return this.dynamicReportsHttpService.get(DYNAMIC_REPORTS_API_URLS().GET_REPORT_FILTER, reqParams);
  }

  getHierarchicalReport(reqParams) {
    const datasource = DATASOURCE_CONFIG[this.viewReportDetails.template_id][this.viewReportDetails?.datasource_id];
    return this.dynamicReportsHttpService.get(DYNAMIC_REPORTS_API_URLS().GET_HIERARCHICAL_REPORT.replace('{datasource_name}',datasource), reqParams);
  }
}
