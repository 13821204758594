import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MyReportsService } from '../../services/my-reports.service';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { BuildReportsService } from '../../../../modules/build-reports/services/build-reports.service';
import { ViewReportService } from '../../../view-report/services/view-report.service';
import { BuildReportsStepperDialogComponent } from '../../../../shared/components/build-reports-stepper-dialog/build-reports-stepper-dialog.component';
import { ShareReportDialogComponent } from '../../../../shared/components/share-report-dialog/share-report-dialog.component';
import { IReportTemplate } from '../../../../modules/build-reports/interface/build-report.interface';
import { IMyReports } from '../../interface/my-reports.interface';
import {
  CONFIRM_MESSAGE,
  DYNAMIC_REPORTS_API_URLS,
  MAT_PAGINATOR_CONFIG,
  REPORT_ACTION,
  WDR_CONSTANT
} from '../../../../constants/wdr.constant';

@Component({
  selector: 'app-my-reports-dashboard',
  templateUrl: './my-reports-dashboard.component.html',
  styleUrls: ['./my-reports-dashboard.component.scss'],
})
export class MyReportsDashboardComponent implements OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;
  myReportAction = REPORT_ACTION;
  noReportMessage = WDR_CONSTANT.noReportsMessage;
  matPaginatorConfig = JSON.parse(JSON.stringify(MAT_PAGINATOR_CONFIG));
  userReports: IMyReports[] = [];
  userReportsFiltered: IMyReports[] = [];
  isSharedReport: boolean;
  navReportId: number;
  pageSizeBeforeNavigation: number;
  isReportUpdatedObsSub: Subscription = new Subscription();
  filterText = '';
  totalReportsCount = 0;
  activeReports: IReportTemplate[] = [];
  selectedReportType = null;

  constructor(
    private readonly myReportsService: MyReportsService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    private readonly buildReportsService: BuildReportsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly viewReportService: ViewReportService,
    private readonly paginatorIntl: MatPaginatorIntl
  ) {}

  ngOnInit(): void {
    this.loadActiveReports();
    this.activatedRoute.data.subscribe((data) => {
      this.isSharedReport = data['isSharedReport'];
      if (history?.state?.data?.reportId) {
        this.navReportId = history.state.data['reportId'];
        this.pageSizeBeforeNavigation = history.state.data['pageSize'];
        this.filterText = history.state.data['filterText'] || '';
        this.selectedReportType = history.state.data['selectedReportType'] || null;
        this.matPaginatorConfig.currentPage = history.state.data['pageIndex'] || 0;
      }
      this.getMyReports();
    });
    this.isReportUpdatedObsSub.add(
      this.myReportsService.isReportUpdatedObs.subscribe(() => {
        this.getMyReports();
      })
    );
  }

  loadActiveReports(): void {
    this.activeReports = this.buildReportsService.reportTemplates || [];
    if (!this.activeReports.length) {
      this.buildReportsService.getReportTemplates().subscribe({
        next: (responseData) => {
          this.activeReports = responseData;
        },
        error: (responseData) => {
          this.dynamicReportsUtilityService.showErrorMessage(responseData);
        },
      });
    }
  }

  getMyReports(callBackMessage = ''): void {
    const apiURL = this.isSharedReport ? DYNAMIC_REPORTS_API_URLS().GET_SHARED_REPORT : DYNAMIC_REPORTS_API_URLS().GET_MY_REPORTS;
    this.myReportsService.getMyReports(apiURL).subscribe({
      next: (responseData) => {
        this.userReports = JSON.parse(JSON.stringify(responseData));
        if (this.navReportId) {
          setTimeout(() => this.goToPageContainingReport(this.matPaginatorConfig.currentPage));
        } else {
          this.updatePaginatedReports();
        }
        this.updatePaginatedReports();
        if (callBackMessage) {
          this.dynamicReportsUtilityService.showSuccessMessage(callBackMessage);
        }
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData);
      },
    });
  }

  onPageChange(event: PageEvent) {
    this.matPaginatorConfig.pageSize = event.pageSize;
    this.matPaginatorConfig.currentPage = event.pageIndex;
    this.updatePaginatedReports();
  }

  updatePaginatedReports() {
    const startIndex = this.matPaginatorConfig.currentPage * this.matPaginatorConfig.pageSize;
    const endIndex = startIndex + this.matPaginatorConfig.pageSize;
    this.userReportsFiltered = this.filterUserReportByReportTypeAndSearchText()?.slice(startIndex, endIndex) || [];
    this.updateTotalReportsCount();
  }

  filterUserReportByReportTypeAndSearchText(): IMyReports[] {
    const filterText = this.filterText?.trim() || '';
    return filterText || this.selectedReportType
      ? this.userReports.filter((userReport: IMyReports) => {
          const matchesReportType = this.selectedReportType ? userReport.template_id === +this.selectedReportType : true;
          const matchesFilterText = filterText ? userReport.report_title.toLowerCase().includes(filterText.toLowerCase()) : true;
          return matchesReportType && matchesFilterText;
        })
      : this.userReports;
  }

  updateTotalReportsCount() {
    this.totalReportsCount = this.filterUserReportByReportTypeAndSearchText()?.length || 0;
  }

  goToPageContainingReport(reportIndex: number) {
    if (reportIndex !== -1) {
      const pageSize = this.pageSizeBeforeNavigation || this.matPaginatorConfig.pageSize;
      if (this.navReportId) {
        this.matPaginatorConfig.currentPage = reportIndex;
      }
      this.matPaginatorConfig.pageSize = pageSize;
      if (this.paginator) {
        this.paginator.pageSize = pageSize; // Set the page size
        this.paginator.pageIndex = this.matPaginatorConfig.currentPage; // Set the page index
      }
      this.updatePaginatedReports(); // Update the reports
      this.paginatorIntl?.changes?.next(); // Trigger the paginator changes
    }
  }

  getReportIndexById(reportId: number) {
    return this.userReports.findIndex((userReport) => userReport.id === reportId);
  }

  reportAction(report: IMyReports, action: string) {
    let message = '';
    const reportIndex = this.getReportIndexById(report.id);
    if (action === this.myReportAction.COPY) {
      message = CONFIRM_MESSAGE.COPY_REPORT(report.report_title);
    } else if (action === this.myReportAction.DELETE) {
      message = CONFIRM_MESSAGE.DELETE_REPORT(report.report_title);
    } else if (action === this.myReportAction.UPDATE) {
      this.updateReport({ report, action, reportIndex });
    } else if (action === this.myReportAction.SHARE) {
      this.shareReport(report);
    }
    if (message) {
      const option = { message: message };
      this.dynamicReportsUtilityService
        .showConfirmDialog(option)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.reportActionCallBack(report, action, reportIndex);
          }
        });
    }
  }

  reportActionCallBack(report: IMyReports, action: string, reportIndex: number) {
    if (action === this.myReportAction.COPY) {
      this.copyReport(report);
    } else if (action === this.myReportAction.DELETE) {
      this.deleteReport(report.id, reportIndex);
    }
  }

  copyReport(report: IMyReports) {
    const copyRequest = {
      report_id: report.id,
      report_title: report.report_title,
      action: this.myReportAction.COPY,
      parent_report_id: report.parent_report_id,
    };
    this.myReportsService.copyReport(copyRequest).subscribe({
      next: (responseData) => {
        this.getMyReports(responseData.message);
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData.message);
      },
    });
  }

  deleteReport(reportId: number, reportIndex: number) {
    const deleteRequest = { report_id: reportId };
    this.myReportsService.deleteReport(deleteRequest).subscribe({
      next: (responseData) => {
        this.userReports.splice(reportIndex, 1);
        this.updatePaginatedReports();
        this.dynamicReportsUtilityService.showSuccessMessage(responseData.message);
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData.message);
      },
    });
  }

  updateReport(reportDetails) {
    const reportType = {
      id: reportDetails.report.template_id,
      template_name: reportDetails.report.template_name,
    };
    this.dynamicReportsUtilityService.openDialog(BuildReportsStepperDialogComponent, {
      data: { reportType, reportAction: REPORT_ACTION.UPDATE, reportDetails: reportDetails.report, isFirstChange: true },
    });
  }

  shareReport(report: IMyReports) {
    this.dynamicReportsUtilityService.openDialog(ShareReportDialogComponent, {
      data: { reportId: report.id },
    });
  }

  viewReport(report: IMyReports) {
    const templateIdInScope = [1, 2, 3]; // 1-Hierarchical, 2-Comparison, 3-Historical
    const datasourceIdInScope = [1, 2]; // 1-Financial, 2-Sales
    this.viewReportService.viewReportDetails = report;
    if (!templateIdInScope.includes(report?.template_id) || !datasourceIdInScope.includes(report?.datasource_id)) {
      this.dynamicReportsUtilityService.showErrorMessage(WDR_CONSTANT?.blockScopeMessage);
    } else {
      this.router.navigate([`/workout-dynamic-reports/view-report`], {
        state: {
          data: {
            isSharedReport: this.isSharedReport,
            pageSize: this.paginator.pageSize,
            pageIndex: this.paginator.pageIndex,
            filterText: this.filterText.trim() || '',
            selectedReportType: this.selectedReportType || null,
          },
        },
      });
    }
  }

  onChangeReportType(): void {
    this.goToPageContainingReport(0);
  }

  resetFilter(): void {
    this.selectedReportType = null;
    this.filterText = '';
    this.goToPageContainingReport(0);
  }

  ngOnDestroy() {
    this.isReportUpdatedObsSub?.unsubscribe();
    this.navReportId = null;
  }
}
