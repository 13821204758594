import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TEMPLATE_ID, WDR_CONSTANT } from '../../../../constants/wdr.constant';
import { IReportColumnRequestParam, IReportColumnResponse } from '../../../../shared/interface/build-reports-stepper.interface';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { DynamicReportsDialogService } from '../../../../shared/services/dynamic-reports-dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISelectionConfig } from '../../../../shared/interface/common.interface';
import { DROPDOWN_CONFIG } from '../../../../constants/dropdown-config.constant';

@Component({
  selector: 'app-report-columns',
  templateUrl: './report-columns.component.html',
  styleUrls: ['./report-columns.component.scss'],
})
export class ReportColumnsComponent {
  @Input() reportColumnFormGroup: FormGroup;
  @Input() reportCriteriaFormData: IReportColumnRequestParam;
  @Input() reportAction: string;
  @Input() selectedFieldsTableDataSource: any[] = [];
  @ViewChild('selectedFieldsTable', { static: true }) selectedFieldsTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  dragDisabled = true;
  tempSelectedColumn = {};
  reportColumnRequestParam: IReportColumnRequestParam;
  compareReportColumnRequestParam: IReportColumnRequestParam;
  reportColumnDropdownResponse: IReportColumnResponse = {
    field_name: [],
    category: [],
    timeperiod: [],
    comparision: [],
    comparatorQualifier: [],
  };
  allowedReportFieldLimit = WDR_CONSTANT.allowedReportFieldLimit;
  fieldRequiredMessage = WDR_CONSTANT.fieldRequiredMessage;
  templateId = TEMPLATE_ID;

  //Selected Field Table Configuration
  selectedFieldsTableColumn: string[] = ['field_name', 'category_name', 'time_period_name', 'action'];
  emptyData = new MatTableDataSource([{ empty: 'row' }]);

  //DropDown
  fieldNameConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_COLUMN_CONTROL.FIELD_NAME;
  fieldCategoryConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_COLUMN_CONTROL.FIELD_CATEGORY;
  fieldTimePeriodConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_COLUMN_CONTROL.FIELD_TIME_PERIOD;
  comparisonConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_COLUMN_CONTROL.COMPARISON;
  comparatorQualifierConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_COLUMN_CONTROL.COMPARATOR_QUALIFIER;

  get formControl() {
    return this.reportColumnFormGroup.controls;
  }

  constructor(
    public readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    public readonly dynamicReportsDialogService: DynamicReportsDialogService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['reportCriteriaFormData'] &&
      !this.dynamicReportsUtilityService.compareObject(changes['reportCriteriaFormData']?.currentValue, changes['reportCriteriaFormData']?.previousValue)
    ) {
      this.reportColumnFormGroup.reset();
      this.tempSelectedColumn = {};
      this.reportColumnDropdownResponse = {
        field_name: [],
        category: [],
        timeperiod: [],
        comparision: [],
        comparatorQualifier: [],
      };
      this.prepareColumnRequestParam();
    }
  }

  ngOnInit(): void {
    this.setSelectedFieldsTableColumnByTemplateId();
    this.valueChanges();
  }

  setSelectedFieldsTableColumnByTemplateId() {
    if (this.getReportTemplateId() === TEMPLATE_ID.COMPARISON) {
      this.selectedFieldsTableColumn = ['field_name', 'category_name', 'time_period_name', 'comparison_name', 'action'];
    } else if (this.getReportTemplateId() === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      this.selectedFieldsTableColumn = ['field_name', 'category_name', 'time_period_name', 'display_name', 'comparison_name', 'action'];
    }
  }

  getReportTemplateId() {
    return this.modalData.reportType.id;
  }

  valueChanges() {
    this.formControl['fieldName']?.valueChanges?.subscribe((value) => {
      this.resetFieldControl('fieldCategory', value);
      this.resetFieldControl('fieldTimePeriod');
      if (value) {
        this.prepareColumnRequestParam();
      }
    });

    this.formControl['fieldCategory']?.valueChanges?.subscribe((value) => {
      this.resetFieldControl('fieldTimePeriod', value);
      if (value) {
        this.prepareColumnRequestParam();
      }
    });

    if (this.getReportTemplateId() === TEMPLATE_ID.COMPARISON) {
      this.formControl['fieldTimePeriod']?.valueChanges?.subscribe((value) => {
        this.resetFieldControl('comparision', value);
        if (value) {
          this.prepareColumnRequestParam();
        }
      });
    }
    if (this.getReportTemplateId() === TEMPLATE_ID.EXTERNAL_COMPARISON) {
      this.formControl['fieldTimePeriod']?.valueChanges?.subscribe((value) => {
        this.resetFieldControl('comparatorQualifier', value);
        if (value) {
          this.prepareColumnRequestParam();
        }
      });
    }
  }

  prepareColumnRequestParam() {
    if (this.reportCriteriaFormData) {
      this.reportColumnRequestParam = this.dynamicReportsUtilityService.deepCopy(this.reportCriteriaFormData);
    }
    if (this.formControl['fieldName'].value) {
      // this.reportColumnRequestParam['source_field_id'] = +this.formControl['fieldName'].value;
      this.reportColumnRequestParam = { ...this.reportColumnRequestParam, source_field_id: +this.formControl['fieldName'].value };
    }

    if (this.formControl['fieldCategory'].value) {
      // this.reportColumnRequestParam['category_field_id'] = +this.formControl['fieldCategory'].value;
      this.reportColumnRequestParam = { ...this.reportColumnRequestParam, category_field_id: +this.formControl['fieldCategory'].value };
    }

    if (this.formControl['fieldTimePeriod'].value) {
      // this.reportColumnRequestParam['column_timeperiod_id'] = +this.formControl['fieldTimePeriod'].value;
      this.reportColumnRequestParam = { ...this.reportColumnRequestParam, column_timeperiod_id: +this.formControl['fieldTimePeriod'].value };
    }
    this.getReportColumns();
  }

  getReportColumns() {
    this.dynamicReportsDialogService.getReportColumn(this.reportColumnRequestParam).subscribe({
      next: (responseData: IReportColumnResponse) => {
        for (const [optionKey, optionValue] of Object.entries(responseData)) {
          this.reportColumnDropdownResponse[optionKey] = optionValue;
        }
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData);
      },
    });
  }

  resetFieldControl(controlName: string, value = '') {
    this.formControl[controlName].setValue(null);
    this.formControl[controlName].disable();
    this.formControl[controlName].markAsUntouched();
    this.formControl[controlName].setErrors(null);
    if (value) {
      this.formControl[controlName].enable();
    }
  }

  drop(event: any) {
    this.dragDisabled = true;
    const previousIndex = this.selectedFieldsTableDataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.selectedFieldsTableDataSource, previousIndex, event.currentIndex);
    this.selectedFieldsTable.renderRows();
  }

  mapDataSource(dataSource: any) {
    dataSource['selectedOptions'].forEach((selectedOption: any) => {
      this.tempSelectedColumn[dataSource.controlName] = selectedOption;
    });
  }

  onBlurDisplayName() {
    const displayName = this.formControl['displayName']?.value?.trim() || '';
    this.formControl['displayName']?.setValue(displayName);
    this.tempSelectedColumn['displayName'] = displayName;
  }

  validateAddItem() {
    if (this.reportColumnFormGroup.valid) {
      if (!this.checkForDuplicateColumn()) {
        let rowData = {
          source_field_id: this.tempSelectedColumn['fieldName']['id'],
          field_name: this.tempSelectedColumn['fieldName']['field_name'],
          category_id: this.tempSelectedColumn['fieldCategory']['id'],
          category_name: this.tempSelectedColumn['fieldCategory']['category_name'],
          time_period_id: this.tempSelectedColumn['fieldTimePeriod']['id'],
          time_period_name: this.tempSelectedColumn['fieldTimePeriod']['time_period_name'],
        };
        if (this.getReportTemplateId() === TEMPLATE_ID.COMPARISON) {
          const addOn = {
            comparison_type_id: this.tempSelectedColumn['comparision']['id'],
            comparison_name: this.tempSelectedColumn['comparision']['comparision_name'],
          };
          rowData = { ...rowData, ...addOn };
        } else if (this.getReportTemplateId() === TEMPLATE_ID.EXTERNAL_COMPARISON) {
          const addOn = {
            display_name: this.tempSelectedColumn['displayName'],
            comparison_type_id: this.tempSelectedColumn['comparatorQualifier']['id'],
            comparison_name: this.tempSelectedColumn['comparatorQualifier']['comparator_qualifier_name'],
          };
          rowData = { ...rowData, ...addOn };
        }
        this.selectedFieldsTableDataSource.push(rowData);
        this.selectedFieldsTable.renderRows();
        this.resetItem();
        this.dynamicReportsUtilityService.showSuccessMessage(WDR_CONSTANT.fieldAddedSuccessMessage);
      } else {
        this.dynamicReportsUtilityService.showErrorMessage(WDR_CONSTANT.fieldAlreadyExistMessage);
      }
    }
  }

  isFieldNameCategoryTimePeriodExists(tableData) {
    return (
      tableData['source_field_id'] === this.tempSelectedColumn['fieldName']['id'] &&
      tableData['category_id'] === this.tempSelectedColumn['fieldCategory']['id'] &&
      tableData['time_period_id'] === this.tempSelectedColumn['fieldTimePeriod']['id']
    );
  }

  checkForDuplicateColumn(): boolean {
    return this.selectedFieldsTableDataSource.find((tableData) => {
      if (this.getReportTemplateId() === TEMPLATE_ID.HIERARCHICAL || this.getReportTemplateId() === TEMPLATE_ID.HISTORICAL) {
        return this.isFieldNameCategoryTimePeriodExists(tableData);
      } else if (this.getReportTemplateId() === TEMPLATE_ID.COMPARISON) {
        return this.isFieldNameCategoryTimePeriodExists(tableData) && tableData['comparison_type_id'] === this.tempSelectedColumn['comparision']['id'];
      } else if (this.getReportTemplateId() === TEMPLATE_ID.EXTERNAL_COMPARISON) {
        return (
          this.isFieldNameCategoryTimePeriodExists(tableData) &&
          tableData['display_name'] === this.tempSelectedColumn['displayName'] &&
          tableData['comparison_type_id'] === this.tempSelectedColumn['comparatorQualifier']['id']
        );
      }
      return false;
    });
  }

  resetItem() {
    this.reportColumnFormGroup.reset();
    this.reportColumnFormGroup.get('displayName')?.setErrors(null);
    this.tempSelectedColumn = {};
  }

  deleteReportColumn(rowIndex: number) {
    this.selectedFieldsTableDataSource.splice(rowIndex, 1);
    this.selectedFieldsTable.renderRows();
  }
}
