import { Component } from '@angular/core';
import { ViewReportService } from '../../services/view-report.service';
import { IMyReports } from '../../../my-reports/interface/my-reports.interface';
import { REPORT_CATEGORY } from '../../../../constants/wdr.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-report-container',
  templateUrl: './view-report-container.component.html',
  styleUrls: ['./view-report-container.component.scss'],
})
export class ViewReportContainerComponent {
  viewReportDetails: IMyReports;
  isSharedReport: boolean;
  pageSize: number;
  pageIndex: number;
  filterText: string;
  selectedReportType: string;

  constructor(private readonly router: Router, public viewReportService: ViewReportService) {}

  ngOnInit() {
    this.viewReportDetails = this.viewReportService.viewReportDetails;
    if (this.viewReportDetails) {
      this.isSharedReport = history.state.data['isSharedReport'];
      this.pageSize = history.state.data['pageSize'];
      this.pageIndex = history.state.data['pageIndex'];
      this.filterText = history.state.data['filterText'];
      this.selectedReportType = history.state.data['selectedReportType'];
    } else {
      this.router.navigate(['/workout-dynamic-reports/home']);
    }
  }

  emittedReportData(event) {
    this.viewReportService.viewReportTableData = event;
  }

  backToReport() {
    this.viewReportService.viewReportTableData = null;
    const navigateTo = this.isSharedReport ? REPORT_CATEGORY.SHARED : REPORT_CATEGORY.MY_REPORT;
    const navStateData = {
      isSharedReport: this.isSharedReport,
      reportId: this.viewReportDetails.id,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      filterText: this.filterText,
      selectedReportType: this.selectedReportType,
    };
    this.router.navigate([`/workout-dynamic-reports/${navigateTo}`], { state: { data: navStateData } });
  }
}
