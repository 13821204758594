<div class="dxfw-wdr-my-report-search-container dxfw-wdr-form-container">
  <div class="d-flex m-t-20">
    <div class="dxfw-wdr-mat-select-container">
      <label for="selectedReportType" class="dxfw-wdr-my-report-search-label wdr-stepper-label m-r-10">Report Type</label>
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Report Type'"
          required
          disableRipple
          class="dd-mat-mdc-select"
          panelClass="dxfw-wdr-select-panel"
          [(value)]="selectedReportType"
          (selectionChange)="onChangeReportType()">
          <mat-option>Select Report Type</mat-option>
          <mat-option *ngFor="let report of activeReports" value="{{ report.id }}">
            {{ report.template_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="m-r-15">
      <label for="searchReportInput" class="dxfw-wdr-my-report-search-label wdr-stepper-label m-r-10">Search Report</label>
      <mat-form-field class="dxfw-wdr-my-report-search dd-mat-mdc-input-form-field">
        <input
          matInput
          id="searchReportInput"
          #searchInput
          [(ngModel)]="filterText"
          (keyup)="goToPageContainingReport(0)"
          (keydown)="$event.stopPropagation()"
          placeholder="Enter Report Title" />
      </mat-form-field>
    </div>
    <button class="primary-button action small-button m-l-15 m-t-5" title="Reset" (click)="resetFilter()">Reset</button>
  </div>
</div>
<div class="dxfw-wdr-my-report-container">
  <ng-container *ngIf="userReportsFiltered && userReportsFiltered.length; else noMyReport">
    <mat-card *ngFor="let userReport of userReportsFiltered">
      <mat-card-content>
        <div class="dxfw-wdr-my-report-cont__title">
          <a (click)="viewReport(userReport)">{{ userReport.report_title }}</a>
        </div>
        <div class="dxfw-wdr-my-report-cont__desc">{{ userReport.report_description }}</div>
      </mat-card-content>
      <mat-card-footer>
        <div class="dxfw-wdr-my-report-cont__actions">
          <button class="primary-button action small-button" title="Share" (click)="reportAction(userReport, myReportAction.SHARE)">
            <mat-icon>share</mat-icon>
          </button>
          <button *ngIf="!isSharedReport" class="primary-button action small-button" title="Edit" (click)="reportAction(userReport, myReportAction.UPDATE)">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="primary-button action small-button" title="Copy" (click)="reportAction(userReport, myReportAction.COPY)">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button *ngIf="!isSharedReport" class="primary-button action small-button" title="Delete" (click)="reportAction(userReport, myReportAction.DELETE)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </ng-container>
  <ng-template #noMyReport>
    <div class="no-record full-width text-center">{{ noReportMessage }}</div>
  </ng-template>
  <div class="dxfw-wdr-my-report-paginator-container" *ngIf="totalReportsCount">
    <mat-paginator
      #paginator
      [length]="totalReportsCount"
      [pageSize]="matPaginatorConfig.pageSize"
      [pageSizeOptions]="matPaginatorConfig.pageSizeOptions"
      [showFirstLastButtons]="matPaginatorConfig.showFirstLastButtons"
      [selectConfig]="matPaginatorConfig.selectConfig"
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>
